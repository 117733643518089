import {
  AxiosError,
  AxiosInstance,
  HttpStatusCode,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios'
import { jwtDecode } from 'jwt-decode'

import { requestTokenRefresh } from '../requestTokenRefresh'
import { returnError } from './helpers'

interface TokenRejectRequestInterceptorProps {
  accessToken?: string
}

export function tokenRejectRequestInterceptor({
  accessToken,
}: TokenRejectRequestInterceptorProps) {
  return (config: InternalAxiosRequestConfig) => {
    if (!accessToken) {
      throw new AxiosError('access_token is missing', '401', config)
    }
    return config
  }
}

interface TokenRejectResponseInterceptorProps {
  authenticatedAxiosClient: AxiosInstance
  refreshToken?: string
}

function generateRefreshTokenRequest(
  refreshToken: string,
  error: Error,
  authenticatedAxiosClient: AxiosInstance,
  originalRequest: InternalAxiosRequestConfig<string | Record<string, string>>
) {
  let tokenPartsRefresh: { exp: number }
  try {
    tokenPartsRefresh = jwtDecode<{ exp: number }>(refreshToken)
  } catch (err) {
    return returnError(
      { ...(err as Error), message: 'Refresh token is malformed' },
      true
    )
  }

  const now = Math.ceil(Date.now() / 1000)
  if (tokenPartsRefresh.exp < now) {
    return returnError(error, true)
  }

  return requestTokenRefresh({
    authenticatedAxiosClient,
    originalRequest,
    refreshToken,
  })
}

export function tokenRejectResponseInterceptor({
  authenticatedAxiosClient,
  refreshToken,
}: TokenRejectResponseInterceptorProps) {
  return async (error: AxiosError | Error) => {
    // istanbul ignore next
    if (!isAxiosError(error) || !error.config) {
      return returnError(error)
    }

    const { config: originalRequest, response, code } = error

    if (!response) {
      // there is no response, so it must be a request rejection
      if (Number(code) !== HttpStatusCode.Unauthorized) {
        return returnError(error)
      }

      if (!refreshToken) {
        return returnError(error, true)
      }

      return generateRefreshTokenRequest(
        refreshToken,
        error,
        authenticatedAxiosClient,
        originalRequest
      )
    }

    if (
      response.status !== HttpStatusCode.Unauthorized ||
      originalRequest?.url === '/api/token/refresh/'
    ) {
      return returnError(error)
    }

    if (!refreshToken) {
      return returnError(error, true)
    }

    return generateRefreshTokenRequest(
      refreshToken,
      error,
      authenticatedAxiosClient,
      originalRequest
    )
  }
}
