// https://help.smartling.com/hc/en-us/articles/360000322194-Integration-Tag-Library
import { useEffect, useState } from 'react'

export interface SmartlingAttributesType {
  translate: { translate: 'yes' }
  noTranslate: { translate: 'no' }
}

export const SmartlingAttributes: SmartlingAttributesType = {
  translate: { translate: 'yes' },
  noTranslate: { translate: 'no' },
}

export enum SmartlingClassNames {
  blacklistContent = 'sl_whiteout',
  preventLinkRewrite = 'sl_opaque',
  hideContent = 'sl_hide',
}

export enum SmartlingJSONKeys {
  translate = 'sl_translate',
  noTranslate = 'sl_notranslate',
  path = 'sl_path', // use this for targeting nested items in an object
}

export enum SmartlingJSONValues {
  translateAll = 'sl_all',
  translateNone = 'sl_none',
}

export enum ActiveLanguages {
  english = 'en',
  french = 'fr',
  flemish = 'nl-be',
  chineseSimplified = 'zh-cn',
  chineseTraditional = 'zh-hk',
}

export enum SmartlingHTMLLanguages {
  english = 'en',
  french = 'fr-FR',
  flemish = 'nl-BE',
  chineseSimplified = 'zh-CN',
  chineseTraditional = 'zh-HK',
}

export enum LocaleString {
  default = 'default',
  english = 'en',
  french = 'fr',
  flemish = 'nl-be',
  chineseSimplified = 'zh-cn',
  chineseTraditional = 'zh-hk',
}

export enum LocaleURLFolder {
  default = 'default',
  english = 'en',
  french = 'fr',
  flemish = 'nl-be',
  chineseSimplified = 'zh-cn',
  chineseTraditional = 'zh-hk',
}

export type ELocaleStrings = `${LocaleString}`
export type ELocaleURLFolder = `${LocaleURLFolder}`
export type EActiveLanguages = `${ActiveLanguages}`

export function stripLocaleFromPath(asPath: string) {
  const localeFolderStrings = [
    `/${LocaleURLFolder.english}`,
    `/${LocaleURLFolder.french}`,
    `/${LocaleURLFolder.flemish}`,
    `/${LocaleURLFolder.chineseSimplified}`,
    `/${LocaleURLFolder.chineseTraditional}`,
  ]

  const matchingFolder = localeFolderStrings.filter((localeFolder) =>
    asPath.startsWith(localeFolder)
  )

  if (matchingFolder.length > 0) {
    return asPath.substring(matchingFolder[0].length, asPath.length)
  }

  return asPath
}

export function getLocaleFromPath(asPath: string) {
  const localeFolderStrings = [
    `/${LocaleURLFolder.english}`,
    `/${LocaleURLFolder.french}`,
    `/${LocaleURLFolder.flemish}`,
    `/${LocaleURLFolder.chineseSimplified}`,
    `/${LocaleURLFolder.chineseTraditional}`,
  ]

  const matchingFolder = localeFolderStrings.find((localeFolder) =>
    asPath.startsWith(localeFolder)
  )

  return matchingFolder
    ? matchingFolder.replace(/\//g, '')
    : LocaleURLFolder.english
}

export function useGetLocaleFromHtml() {
  const [htmlLocale, setHtmlLocale] = useState<SmartlingHTMLLanguages>(
    SmartlingHTMLLanguages.english
  )

  useEffect(() => {
    const locale = (document.documentElement.getAttribute('lang') ??
      SmartlingHTMLLanguages.english) as SmartlingHTMLLanguages

    setHtmlLocale(locale)
  }, [])

  return htmlLocale
}
