import { axiosInstance } from '@nx/fetch'

export async function getSessionToken({
  customerId,
  xm_id,
  baseUrl,
}: GetSessionTokenProps) {
  return await axiosInstance
    .post<string>(
      '/api/session/',
      {
        id: customerId,
        xm_id,
      },
      {
        baseURL: baseUrl,
      }
    )
    .then((response) => response.data)
}

export interface GetSessionTokenProps {
  customerId: string | number
  xm_id?: string
  baseUrl: string
}
