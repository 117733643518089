import {
  DefaultOptions,
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppProps } from 'next/app'
import { useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { ScrollPositionProvider } from '@nx/fire/scroll-position'
import { Brand, EBrand } from '@nx/global-types'

import { ModalManager } from '@web/components/common/ModalManager/ModalManager'

import { getTheme } from '../helpers/utils'
import { GlobalProps } from '../types/app'
import { CurrencyConfig, UserContextProvider } from './UserInfoProvider'

export interface AppProvidersProps {
  pageProps?: AppProps['pageProps']
  globalProps?: GlobalProps
  children: React.ReactNode
  queryClientConfig?: {
    queryCache?: QueryCache
    mutationCache?: MutationCache
    defaultOptions?: DefaultOptions
  }
  brand?: EBrand
  currencyConfig?: CurrencyConfig | undefined
}

const AppProviders = ({
  pageProps,
  globalProps,
  queryClientConfig,
  children,
  brand = Brand.bonhams,
  currencyConfig,
}: AppProvidersProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        ...queryClientConfig,
        defaultOptions: {
          ...queryClientConfig?.defaultOptions,
          queries: {
            refetchOnWindowFocus: false,
            ...queryClientConfig?.defaultOptions?.queries,
          },
        },
      })
  )

  const theme = getTheme(brand)

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={globalProps?.dehydratedState}>
        <HydrationBoundary state={pageProps?.dehydratedState}>
          <ScrollPositionProvider>
            <UserContextProvider value={{ currencyConfig }}>
              <ThemeProvider theme={theme}>
                <ModalManager>{children}</ModalManager>
              </ThemeProvider>
            </UserContextProvider>
          </ScrollPositionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </HydrationBoundary>
      </HydrationBoundary>
    </QueryClientProvider>
  )
}

export default AppProviders
