export const GTM_PLATFORM_ID = 'NX'

export const GTM_EVENTS = {
  productDetail: 'productDetail',
  userLogin: 'userLogin',
  registrationComplete: 'registrationComplete',
  currencySelection: 'CurrencySelection',
  specialistContactForm: 'specialistContactForm',
  breakoutFilter: 'breakoutFilter',
  groupsFilter: 'groupsFilter',
  userInteraction: 'User Interaction',
  bidNow: 'bidNow',
  registerNow: 'registerNow',
} as const

export const GTM_DIMENSION_7 = {
  loggedIn: 'LoggedIn',
  notLoggedIn: 'Not Logged In',
} as const

export const GTM_AUCTION_TYPE = {
  ONLINE: 'timed',
  LIVE: 'live',
} as const

export const GTM_AUCTION_ONLINE_STATUS = {
  EN: 'Auction ended',
  IP: 'Auction in progress',
  NS: 'Auction not started',
  PD: 'Auction paused',
} as const

export enum UserPersona {
  Guest = 'guest',
  Known = 'known',
  LoggedIn = 'logged-in',
}
