export const COOKIE_EXPIRATION = 365 //days

//Various Cookie Names
export const COOKIE_NAME_SUBSCRIPTION = 'newsletterSubscribed'
export const COOKIE_NAME_BANNER_DISMISSED = 'bannerDismissed'

//GTM events
export const EVENT_EMAIL_SIGNUP = 'emailSignup'
export const EVENT_EMAIL_SIGNUP_FAIL = 'emailSignupFail'
export const EVENT_EMAIL_SIGNUP_DECLINE = 'emailSignupDecline'
export const EVENT_EMAIL_SIGNUP_TRIGGER = 'emailSignupTrigger'
export const EVENT_EMAIL_SIGNUP_PROMPT = 'emailSignupPrompt'
