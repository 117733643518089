import { Dispatch, createContext } from 'react'

import { LoginModalProps } from '@web/components/layout/LoginModal'

import type { LotCardBidModalProps } from '../LotCardBidModal/LotCardBidModal'
import type { LotCardRegisterModalProps } from '../LotCardRegisterModal/LotCardRegisterModal'

export const modalsInitialState: NoModalState = {
  modal: null,
  props: null,
}

export const ModalsDispatchContext =
  createContext<Dispatch<ModalAction> | null>(null)

export function modalsReducer(
  state: ModalState,
  action: ModalAction
): ModalState {
  switch (action.type) {
    case 'CLOSE':
      return modalsInitialState
    case 'OPEN':
      return action.payload
  }
}

export type NoModalState = { modal: null; props: null }
export type BidModalState = {
  modal: 'BID_NOW'
  props: Omit<LotCardBidModalProps, 'setIsOpen'>
}
export type RegisterModalState = {
  modal: 'REGISTER_NOW'
  props: Omit<LotCardRegisterModalProps, 'setIsOpen'>
}
export type LoginModalState = {
  modal: 'LOGIN'
  props: Omit<LoginModalProps, 'setIsOpen' | 'defaultScreen'>
}
export type CreateAccountModalState = {
  modal: 'CREATE_ACCOUNT'
  props: Omit<LoginModalProps, 'setIsOpen' | 'defaultScreen'>
}
export type ResetPasswordCompleteModalState = {
  modal: 'RESET_PASSWORD_COMPLETE'
  props: Omit<LoginModalProps, 'setIsOpen' | 'defaultScreen'>
}
export type ResetPasswordModalState = {
  modal: 'RESET_PASSWORD'
  props: Omit<LoginModalProps, 'setIsOpen' | 'defaultScreen'>
}

export type ModalState =
  | NoModalState
  | BidModalState
  | RegisterModalState
  | LoginModalState
  | CreateAccountModalState
  | ResetPasswordCompleteModalState
  | ResetPasswordModalState

export type ModalAction = OpenModalAction | CloseModalAction

export type OpenModalAction = {
  type: 'OPEN'
  payload:
    | BidModalState
    | RegisterModalState
    | LoginModalState
    | CreateAccountModalState
    | ResetPasswordCompleteModalState
    | ResetPasswordModalState
}

export type CloseModalAction = {
  type: 'CLOSE'
  payload: NoModalState | undefined
}
