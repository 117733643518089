import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const cornetteBaseUrl = publicRuntimeConfig.CORNETTE_BASE_URL

const globalSettings: Pick<RedirectConfig, 'hostname' | 'permanent'> = {
  hostname: 'www.cornettedesaintcyr.fr',
  permanent: true,
} as const

const redirects: Pick<RedirectConfig, 'source' | 'destination'>[] = [
  // Homepage
  {
    source: /^\/$/,
    destination: cornetteBaseUrl,
  },
  // Auction Calendar
  {
    source: '/calendrier/',
    destination: `${baseUrl}/auctions/upcoming/?countries=France`,
  },
  // Auction Results
  {
    source: '/resultats/',
    destination: `${baseUrl}/auctions/results/?countries=France`,
  },
  // About Us
  {
    source: '/la-maison/letude/',
    destination: `${cornetteBaseUrl}/about-us/`,
  },
  // Departments
  {
    source: '/la-maison/departements/art-contemporain/',
    destination: `${baseUrl}/department/PIC-CON/`,
  },
  {
    source: '/la-maison/departements/art-impressionniste-et-moderne/',
    destination: `${baseUrl}/department/PIC-IMP/`,
  },
  {
    source: '/la-maison/departements/arts-premiers-arts-dasie/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  {
    source: '/la-maison/departements/bande-dessinees-illustrations/',
    destination: `${baseUrl}/department/BDS/`,
  },
  {
    source: '/la-maison/departements/bijoux-horlogerie-de-collection/',
    destination: `${baseUrl}/department/JWL/`,
  },
  {
    source: '/la-maison/departements/arts-decoratifs-du-xxe-siecle-design/',
    destination: `${baseUrl}/department/MDS/`,
  },
  {
    source: '/la-maison/departements/estampes-multiples/',
    destination: `${baseUrl}/department/PIC-PRT/`,
  },
  {
    source: '/la-maison/departements/automobiles-de-collection/',
    destination: `${publicRuntimeConfig.CARS_BASE_URL}/`,
  },
  {
    source: '/la-maison/departements/haute-couture/',
    destination: `${baseUrl}/department/HBS/`,
  },
  {
    source: '/la-maison/departements/histoire-naturelle-taxidermie/',
    destination: `${baseUrl}/department/NAT/`,
  },
  {
    source: '/la-maison/departements/livres-manuscrits/',
    destination: `${baseUrl}/department/BOK/`,
  },
  {
    source: '/la-maison/departements/mobilier-objets-dart-tableaux-anciens/',
    destination: `${baseUrl}/department/PIC-OMP/`,
  },
  {
    source: '/la-maison/departements/ventes-privees/',
    destination: `${baseUrl}/stories/30242/`,
  },
  {
    source: '/la-maison/departements/ventes-collections-extraordinaires/',
    destination: `${baseUrl}/department/HOU/`,
  },
  {
    source: '/la-maison/departements/vins-et-spiritueux/',
    destination: `${baseUrl}/department/WIN/`,
  },
  {
    source: '/la-maison/departements/photographie/',
    destination: `${baseUrl}/department/PHO/`,
  },
  {
    source: '/la-maison/departements/administration/',
    destination: `${baseUrl}/stories/30262/`,
  },
  {
    source: '/la-maison/departements/',
    destination: `${baseUrl}/department/`,
  },
  // Newsletter Signup
  {
    source: '/fr/Accueil-3.html',
    destination: `${baseUrl}/?email-signup=true`,
  },
  // Location / Contact
  {
    source: '/la-maison/contact/',
    destination: `${baseUrl}/location/PARI/`,
  },
  // Legal
  {
    source: '/acheter-vendre/conditions-generales-de-vente/',
    destination: `${baseUrl}/legals/`,
  },
  {
    source: '/mentions-legales/',
    destination: `${baseUrl}/legals/9944/`,
  },
  // Press Release
  {
    source: '/actualites/',
    destination: `${baseUrl}/press_release/`,
  },
  {
    source: '/category/revue-de-presse/',
    destination: `${baseUrl}/press_release/`,
  },
  // Sell Hub
  {
    source: '/expertiser/',
    destination: `${publicRuntimeConfig.SELL_HUB_BASE_URL}/fr/`,
  },
  {
    source: '/acheter-vendre/',
    destination: `${publicRuntimeConfig.SELL_HUB_BASE_URL}/fr/`,
  },
  // Auctions
  {
    source:
      '/catalogue/127079-la-collection-robert-jeanpierre-rousset-dart-asiatique-un-siecle-dhistoire',
    destination: `${baseUrl}/fr/auction/27939/`,
  },
  {
    source: /\/lot\/127079\/.*/,
    destination: `${baseUrl}/fr/auction/27939/`,
  },
  {
    source:
      '/catalogue/127669-la-collection-robert-jeanpierre-rousset-dart-asiatique-un-siecle-dhistoire',
    destination: `${baseUrl}/fr/auction/27939/`,
  },
  {
    source: /\/lot\/127669\/.*/,
    destination: `${baseUrl}/fr/auction/27939/`,
  },
]

export const cornetteDeSaintCyrFRRedirects: RedirectConfig[] = redirects.map(
  (redirect) => ({ ...globalSettings, ...redirect })
)
