import { AxiosError, isAxiosError } from 'axios'

import { isServer } from '@nx/helpers'
import { removeAuthCookies } from '@nx/user-auth-cookies'

const DEFAULT_STATUS = 500

export function returnError(error: AxiosError | Error, removeLogin = false) {
  if (removeLogin && !isServer()) {
    removeAuthCookies()
  }

  const hasAxiosError = isAxiosError(error)
  const axiosErrorCode =
    hasAxiosError && error.code ? Number(error.code) : undefined

  const status = hasAxiosError
    ? (error.response?.status ?? (axiosErrorCode || DEFAULT_STATUS))
    : // istanbul ignore next
      DEFAULT_STATUS

  return Promise.reject({
    status,
    message: error.message,
    error,
  })
}
