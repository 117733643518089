import { GTM_EVENTS } from './enums'
import { useDataLayer } from './useDataLayer'

export type InteractionAction =
  | 'click'
  | 'auction-link.click'
  | 'auction-card.click'
  | 'live-auction-card.click'
  | 'lot-card.click'
  | 'lot-navigation.click'
  | 'result-typeAhead.click'
  | 'lot-sold-toast-refresh.click'
  | 'auction-typeAhead.click'
  | 'stories-card.click'
  | 'sell-hub-card.click'
  | 'refresh-lots.click'
  | 'registerNow.success'
  | 'bidNow.click'
  | 'language.select'
  | 'sortBy.click'
  | 'costCalculator.click'

export const useUserInteraction = () => {
  const dataLayer = useDataLayer()

  return {
    recordInteraction: ({
      category,
      action,
      label,
    }: {
      category: string
      action: InteractionAction
      label: string
    }) => {
      dataLayer.push({
        event: GTM_EVENTS.userInteraction,
        interactionData: {
          category,
          action,
          label,
        },
      })
    },
  }
}
