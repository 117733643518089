import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const cornetteBaseUrl = publicRuntimeConfig.CORNETTE_BASE_URL

const globalSettings: Pick<RedirectConfig, 'hostname' | 'permanent'> = {
  hostname: 'www.cornettedesaintcyr.be',
  permanent: true,
} as const

const redirects: Pick<RedirectConfig, 'source' | 'destination'>[] = [
  // Homepage
  {
    source: /^\/$/,
    destination: `${cornetteBaseUrl}/en/`,
  },
  // Calendar
  {
    source: '/calendrier-ventes/',
    destination: `${baseUrl}/auctions/upcoming/?countries=Belgium`,
  },
  // Results
  {
    source: '/ventes-passees/',
    destination: `${baseUrl}/auctions/results/?countries=Belgium`,
  },
  // About us
  {
    source: '/la-maison/letude/',
    destination: `${cornetteBaseUrl}/en/about-us/`,
  },
  // Departments
  {
    source: /^\/la-maison\/departements\/$/,
    destination: `${baseUrl}/department/`,
  },
  {
    source: '/la-maison/departements/art-contemporain/',
    destination: `${baseUrl}/department/PIC-CON/`,
  },
  {
    source: '/la-maison/departements/art-belge-et-art-moderne/',
    destination: `${baseUrl}/department/PIC-IMP/`,
  },
  {
    source: '/la-maison/departements/mobilier-objets-dart-tableaux-anciens/',
    destination: `${baseUrl}/department/PIC-OMP/`,
  },
  {
    source: '/la-maison/departements/design/',
    destination: `${baseUrl}/department/MDS/`,
  },
  {
    source: '/la-maison/departements/estampes-multiples/',
    destination: `${baseUrl}/department/PIC-PRT/`,
  },
  {
    source: '/la-maison/departements/automobiles-de-collection/',
    destination: `${publicRuntimeConfig.CARS_BASE_URL}/`,
  },
  {
    source: '/la-maison/departements/restauration/',
    destination: `${baseUrl}/department/`,
  },
  // Newsletter Signup
  {
    source: '/fr/Inscriptiondesinscription-newsletter-82.html',
    destination: `${baseUrl}/?email-signup=true`,
  },
  // Location / Contact
  {
    source: '/la-maison/contact/',
    destination: `${baseUrl}/location/BRU/brussels/`,
  },
  // Sell Hub
  {
    source: '/expertiser/',
    destination: publicRuntimeConfig.SELL_HUB_BASE_URL,
  },
  {
    source: /\/acheter-vendre\/$/,
    destination: publicRuntimeConfig.SELL_HUB_BASE_URL,
  },
  // Legals
  {
    source: '/acheter-vendre/conditions-generales-de-vente/',
    destination: `${baseUrl}/legals/`,
  },
  // Press Release
  {
    source: '/actualites/',
    destination: `${baseUrl}/press_release/`,
  },
  {
    source: '/category/revue-de-presse/',
    destination: `${baseUrl}/press_release/`,
  },
]

export const cornetteDeSaintCyrBERedirects: RedirectConfig[] = redirects.map(
  (redirect) => ({ ...globalSettings, ...redirect })
)
